// ***** Remove inputs "blue" background color in chrome on autocomplete *****

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

// ***** Global styles *****

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 100%;
}
