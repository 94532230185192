@import "../../variables.scss";

.page {
  display: flex;
  flex-direction: row;
  background-color: $lightGrey;

  &__sidebar {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 18.125rem;
    background-color: $white;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: calc(100% - 18.125rem);
    overflow-y: auto;
    padding: 2rem 3rem 2rem 2rem;
    position: relative;
  }
}
