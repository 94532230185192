@import "../../variables.scss";

.logo {
  padding: $spacing-size-xxxl;
  height: 7.5rem;
}

.client-picker {
  border-top: $spacing-size-xxxs solid $lightGrey;
  padding: $spacing-size-xxxl;

  &__select.MuiInputBase-root {
    background-color: #f4f7fe;

    svg {
      right: 1rem;
      top: calc(50% - 0.3em);
      color: #008cf8;
      transition: transform 100ms ease-in-out;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    border-top: $spacing-size-xxxs solid $lightGrey;
    padding: $spacing-size-xxxl;
  }

  &__list-item {
    margin-bottom: $spacing-size-xxl;
  }

  &__link {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    font-weight: 700;
    color: rgba($darkGrey, 0.75);

    &--active {
      color: $secondary;

      .navigation__icon {
        color: $primary;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: $spacing-size-xs;
        height: $spacing-size-xxxl;
        right: -$spacing-size-xxxl;
        background-color: $primary;
        border-radius: $spacing-size-xxs;
      }
    }

    &:hover {
      color: $secondary;

      .navigation__icon {
        color: $primary;
      }
    }
  }

  &__icon {
    margin-right: $spacing-size-lg;
    font-size: $spacing-size-xl;
  }
}

.app-version {
  color: rgba($darkGrey, 0.75);
  text-align: center;
  padding: 1rem;
}